import { stagger,timeline } from "motion"
import "/styles.css"
  
const numSegments = document.querySelectorAll(".segment").length

/**
 * Stagger offset (in seconds)
 * Decrease this to speed the animation up or increase
 * to slow it down.
 */
const offset = 0.09
window.stagger = stagger
let duration = 1
if (sessionStorage.revisited){
    duration = 0.25
}
const setHeight = () => {
    const height = window.innerHeight
    document.body.style.setProperty("--height", `${height}px`)
}
window.addEventListener("resize", setHeight)
setHeight()

setTimeout(() => {
    const sequence = [
        [
            "main > .bg",
            { 
                opacity:[1,0]
            },
            {
                duration: 1,
            }
        
        ],
        [
            ".letter",
            { 
                transform: ['translateY(10px)', 'translateY(0)'],
                opacity: [0, 1,],
            },
            {
                duration: sessionStorage.revisited ? 0.6 : 1,
                delay: stagger(0.1),
            }
        
        ],
        [
            "a,p",
            { 
                transform: ['translateY(10px)', 'translateY(0)'],
                opacity: [0, 1,],
            },
            {
                duration,
                delay: stagger(0.1),
            }
        
        ],
        [
            ".delivery-channels > div",
            { 
                transform: ['translateY(10px)', 'translateY(0)'],
                opacity: [0, 1,],
            },
            {
                duration,
                delay: stagger(0.1),
            }
        
        ],
        [
            "main > img",
            { 
                opacity:[0,1],
                transform: ['translateY(10px) rotate(30deg)', 'translateY(0) rotate(0)'],
            },
            {
                duration: 1,
            }
        
        ],
        [
            ".last",
            { 
                opacity:[0,1],
                transform: ['translateY(10px)', 'translateY(0)'],
            },
            {
                duration: 1,
            }
        
        ],
    ]
    
    timeline(sequence)
    sessionStorage.revisited = true
}, 200);
