import { defaults, noop, time } from '@motionone/utils';
import { stopAnimation } from './stop-animation.es.js';

const createAnimation = (factory) => factory();
const wrapAnimationWithControls = (animationFactory, duration = defaults.duration) => new Proxy({
    animations: animationFactory.map(createAnimation).filter(Boolean),
    duration,
}, controls);
/**
 * TODO:
 * Currently this returns the first animation, ideally it would return
 * the first active animation.
 */
const getActiveAnimation = (state) => state.animations[0];
const controls = {
    get: (target, key) => {
        var _a, _b;
        switch (key) {
            case "duration":
                return target.duration;
            case "currentTime":
                let time = ((_a = getActiveAnimation(target)) === null || _a === void 0 ? void 0 : _a[key]) || 0;
                return time ? time / 1000 : 0;
            case "playbackRate":
                return (_b = getActiveAnimation(target)) === null || _b === void 0 ? void 0 : _b[key];
            case "finished":
                if (!target.finished) {
                    target.finished = Promise.all(target.animations.map(selectFinished)).catch(noop);
                }
                return target.finished;
            case "stop":
                return () => target.animations.forEach((animation) => stopAnimation(animation));
            default:
                return () => target.animations.forEach((animation) => animation[key]());
        }
    },
    set: (target, key, value) => {
        switch (key) {
            case "currentTime":
                value = time.ms(value);
            case "currentTime":
            case "playbackRate":
                for (let i = 0; i < target.animations.length; i++) {
                    target.animations[i][key] = value;
                }
                return true;
        }
        return false;
    },
};
const selectFinished = (animation) => animation.finished;

export { controls, wrapAnimationWithControls };
